import React, { createContext, useState, useEffect } from 'react';  
import Axios from 'axios';
import io from 'socket.io-client';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);   

  useEffect(() => { 
    const authToken = localStorage.getItem('authToken');
    setIsLoggedIn(!!authToken);  
  }, []);

  const login = (token) => {
    setIsLoggedIn(true);
    localStorage.setItem('authToken', token);
    //console.log('Token almacenado en localStorage:', token);
    return token;  
  };


  const InStorelogout = async () => { //

    try {
      const authToken = localStorage.getItem('authToken');
      const authIdUni = localStorage.getItem('CodUniQ');
  
      await Axios.post(
        'https://cloud.viwhite.com/auth/access/sncssinvalied/',
        {
          VUniq: authIdUni,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      // Resto del código aquí, sin esperar la respuesta de la solicitud Axios
      setIsLoggedIn(false);
      localStorage.clear();
      window.location.href = '/';
  
    } catch (error) {
      //console.error('Ups, Algo salió mal', error);
    }
  }
    const logout = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const authIdUni = localStorage.getItem('CodUniQ');
  
      await Axios.post(
        'https://cloud.viwhite.com/auth/access/sncss/',
        {
          VUniq: authIdUni,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      // Resto del código aquí, sin esperar la respuesta de la solicitud Axios
      setIsLoggedIn(false);
      localStorage.clear();
      window.location.href = '/';
  
    } catch (error) {
      //console.error('Ups, Algo salió mal', error);
    }
  };
  

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, login, logout, InStorelogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

