import React from 'react';
import './Loading.css'; 

function Loading() {
  return ( 
      <div className='loadding-ppl'>
        <img src="https://cloud.viwhite.com/icons/iconos/ico.png" alt="" />
      </div> 
  );
}

export default Loading;